<template>
  <div>
    <v-col cols="12">
      <v-card>
        <v-col cols="12" class="pl-0 pr-0 pb-1">
          <v-row>
            <v-spacer />

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-select
                v-model="santralModel"
                :items="santralDataList"
                label="Santral"
                hide-details
              />
            </v-col>

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-select
                v-model="measureSelect"
                :items="measures"
                label=""
                hide-details
              />
            </v-col>
            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                  />
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="1" md="1" class="pr-3">
              <v-btn color="primary" @click="getData"> Filtrele </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <echart
            id="heatmapsHourly"
            class-name="widget-content"
            :height="height"
            width="100%"
            :chart-data="HeatmapData"
          />
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import * as Heatmap from "@/api/Inverter/Heatmap";
import moment from "moment";
import i18n from "@/locale/index";
import Echart from "@/views/components/Echart/Chart.vue";
import { getInverterDataList } from "@/api/Request/inverter";
import helper from "@/api/helper";

export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),

    menu: false,
    modal: false,
    menu2: false,
    mina: "",
    maxa: "",
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),
    minDate: "",

    daterangetext: i18n.t("Tarih Seç"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],

    measureList: [],
    santral_items: [],

    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),

    measures: [],
    measureSelect: "ACPower",
    componentKey: 1,
    title: "",
    santralModel: "",
    inverterList: [],
    inverterNameList: [],
    inverterCount: 0,
    height: "",
    HeatmapData: {},
    windowsWidth: `${document.documentElement.clientWidth - 100}px`,
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      // eslint-disable-next-line consistent-return
      return santralDataList;
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        // eslint-disable-next-line prefer-destructuring, vue/no-side-effects-in-computed-properties
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (this.santralModel !== null && this.santralModel !== undefined) {
        this.setSantralParameters(this.santralModel);
      }
    },
    measureSelect() {
      this.setSantralParameters(this.santralModel);
    },
  },
  created() {},
  mounted() {
    this.startDate = moment().format("YYYY-MM-DD");
    this.finishDate = moment().format("YYYY-MM-DD");
    // eslint-disable-next-line no-use-before-define
    const myVar1 = setInterval(myTimer1, 1000);
    this.inverterCount = 0;
    let k = 0;
    const self = this;
    function myTimer1() {
      const { santralInverterData, santralDataList } = self.$store.getters;

      if (k > 30) {
        clearInterval(myVar1);
      }
      k += 1;

      if (
        santralInverterData !== undefined &&
        Object.keys(santralInverterData).length > 0
      ) {
        clearInterval(myVar1);
        // eslint-disable-next-line prefer-destructuring
        self.santralModel = santralDataList[0];
        self.santralList = santralDataList;
        // self.setSantralParameters(self.santralModel);
      }
    }
  },

  methods: {
    setSantralParameters(selectedSantral) {
      const measureList = [];
      const inverterNameList = [];
      const mList = [];
      const formule = ["avg", "sum"];
      const { santralInverterData } = this.$store.getters;
      const inverterData = santralInverterData[selectedSantral];

      this.inverterCount = 0;
      Object.keys(inverterData).forEach((i) => {
        if (!helper.is_Empty(inverterData[i].general_measure_list)) {
          inverterNameList.push(inverterData[i].INVERTERNAME);
          // eslint-disable-next-line no-plusplus
          this.inverterCount += 1;
          Object.keys(inverterData[i].general_measure_list).forEach((k) => {
            const measure = inverterData[i].general_measure_list[k];
            if (
              mList.indexOf(measure.value) === -1 &&
              formule.indexOf(measure.formuletype) > -1
            ) {
              mList.push(measure.value);
              measureList.push({
                text: i18n.t(measure.text),
                value: measure.value,
                unit: measure.unit,
                type: measure.type,
              });
            }
          });
        }
      });
      this.measures = measureList;
      this.inverterNameList = inverterNameList;
      this.getData();
    },
    getData() {
      this.title = `${this.startDate}/${this.finishDate} ${i18n.t(
        this.measureSelect
      )}`;

      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.startDate,
          column: [this.measureSelect, "INVERTERNAME"],
        },
      };

      this.height = "300px";

      if (this.inverterCount < 11) {
        this.height = "300px";
      }
      if (this.inverterCount > 10 && this.inverterCount < 15) {
        this.height = "400px";
      }

      if (this.inverterCount > 15 && this.inverterCount < 20) {
        this.height = "600px";
      }

      if (this.inverterCount > 20) {
        this.height = "800px";
      }

      this.componentKey += 1;
      const steptime = this.$store.getters.stepTime;
      let selectedMeasure;
      this.measures.forEach((v) => {
        if (this.measureSelect === v.value) {
          selectedMeasure = v;
        }
      });

      const tempdata = getInverterDataList(formdata);
      tempdata.then((res) => {
        const temp = Heatmap.default.heatmapHourly(
          res,
          selectedMeasure,
          this.inverterNameList,
          steptime
        );
        temp.then((r) => {
          this.HeatmapData = r;
        });
      });
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("Date Range");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("Date Range")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        // eslint-disable-next-line prefer-destructuring
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
