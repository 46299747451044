import helper from '@/api/helper';
import chartOptions from '../chart/chart_options';
import request from '../utils';

const heatmapHourly = (res, measureSelect, inverterNameList, steptime) => new Promise(
  (resolve) => {
    const xAxisData = [];

    for (let i = 5; i < 21; i += 1) {
      for (let x = 0; x < 60; x += steptime) {
        let h;
        let m;

        // eslint-disable-next-line no-unused-expressions
        i < 10 ? h = `0${i}` : h = `${i}`;
        // eslint-disable-next-line no-unused-expressions
        x < 10 ? m = `0${x}` : m = `${x}`;
        const time = `${h}:${m}`;

        xAxisData.push(time);
      }
    }

    const yAxisDataLabel = inverterNameList;
    let hourData = [];
    const max = 10;

    if (res.data.success === 'true') {
      Object.keys(res.data.data).forEach((key) => {
        let tmpData = [];

        let val = res.data.data[key][measureSelect.value];

        const {
          INVERTERNAME,
        } = res.data.data[key];

        if (val === undefined) {
          val = 0;
        } else {
          if (typeof val === 'string') {
            val = parseFloat(val);
          }
          val = val.toFixed(1);
        }

        val = parseFloat(val);

        // eslint-disable-next-line radix
        const hour = parseInt(res.data.data[key].Hour);
        // eslint-disable-next-line radix
        const minute = parseInt(res.data.data[key].MinuteGrup);

        let h;
        let m;
        // eslint-disable-next-line no-unused-expressions
        hour < 10 ? h = `0${hour}` : h = `${hour}`;
        // eslint-disable-next-line no-unused-expressions
        minute < 10 ? m = `0${minute}` : m = `${minute}`;
        const time = `${h}:${m}`;

        tmpData = [INVERTERNAME, time, val];
        hourData.push(tmpData);
      });
    }

    hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);
    let name = measureSelect.text;
    if (measureSelect.unit !== '') {
      name = `${measureSelect.text} (${measureSelect.unit})`;
    }
    const min = 0;
    const series = [{
      name,
      type: 'heatmap',
      data: hourData,
      label: {
        show: false,
        fontSize: 10,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    }];

    const grid = {
      height: '80%',
      top: '5%',
      width: '70%',
      right: '6%',

    };
    const tooltip = {
      position: 'right',
    };

    const opt = chartOptions.echartHeatmap_Options(
      series,
      xAxisData,
      yAxisDataLabel,
      min,
      max,
      grid,
      tooltip,
    );

    resolve(opt);
  },
);

const heatmapStatusx = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};

    Object.keys(res.data.data).forEach((key) => {
      const {
        santral,
      } = res.data.data[key];

      if (!_status_list.hasOwnProperty(santral)) {
        _status_list[santral] = [];
      }
      if (!_status_list[santral].hasOwnProperty(res.data.data[key].INVERTER)) {
        _status_list[santral][res.data.data[key].INVERTER] = res.data.data[key];
      }
    });
    console.log(_status_list);

    const opt = {};

    Object.keys(inverterInfo).forEach((item) => {
      let hourData = [];
      const xAxisData = ['s'];
      const yAxisDatas_label = Object.keys(inverterInfo[item].INVERTER);

      console.log(_status_list[item]);
      Object.keys(inverterInfo[item].INVERTER).forEach((k) => {
        let status = 0;

        const inv = inverterInfo[item].INVERTER[k];

        if (!_status_list.hasOwnProperty(item)) {
          _status_list[item] = [];
        }
        if (!_status_list[item].hasOwnProperty(inv.INVERTER)) {
          status = 0;
        } else {
          status = 1;
        }
        console.log(item);
        console.log(inv.INVERTER);
        console.log(_status_list[item][inv.INVERTER]);

        let tmpData = [];
        tmpData = [inv.INVERTER, 's', status];
        hourData.push(tmpData);
      });

      hourData = hourData.map((item) => [item[1], item[0], item[2] || '-']);

      const min = 0;
      const series = [{
        name: '',
        type: 'heatmap',
        data: hourData,
        label: {
          show: true,
          fontSize: 10,
          color: 'black',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      }];

      const grid = {
        height: '85%',
        top: '5%',
        width: '70%',
        right: '6%',

      };
      const tooltip = {
        position: 'right',
      };

      opt[item] = chartOptions.echartHeatmap_Options(
        series,
        xAxisData,
        yAxisDatas_label,
        0,
        1,
        grid,
        tooltip,
        false,
      );
    });

    /*   Object.keys(inverterInfo).forEach((item) => {
        let xAxisData = ['status'];
        let yAxisDatas_label = Object.keys(inverterInfo[item].INVERTER);
        let hourData = [];

        Object.keys(res.data.data).forEach((key) => {
          Object.keys(res.data.data[key]).forEach((k) => {
            let tmpData = [];

            let s = parseInt(k);
            let h;
            let val = res.data.data[key][k][measureSelect];

            if (val == undefined) {
              val = 0;
            } else {
              val = val.toFixed(1);
            }

            val = parseFloat(val);

            if (max < val) {
              max = val;
            }

            let t_val = "";
            if (val > 0) {
              t_val = val
            }

            s < 10 ? (h = `0${s}:00`) : (h = `${s}:00`);
            tmpData = [inverterList[key].INVERTERNAME, h, t_val];
            hourData.push(tmpData)
          });
        });

        hourData = hourData.map(function (item) {
          return [item[1], item[0], item[2] || "-"];
        });

        let min = 0;
        let series = [{
          name: i18n.t(measureSelect),
          type: "heatmap",
          data: hourData,
          label: {
            show: true,
            fontSize: 10,
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        }, ];

        let grid = {
          height: '85%',
          top: '5%',
          width: '70%',
          right: "6%"

        };
        let tooltip = {
          position: "right",
        };

        let opt = chartOptions.echartHeatmap_Options(
          series,
          xAxisData,
          yAxisDatas_label,
          min,
          max,
          grid,
          tooltip
        );
      }); */
    console.log(opt);
    resolve(opt);
  });
});

const heatmapStatuss = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};

    Object.keys(res.data.data).forEach((key) => {
      const {
        santral,
      } = res.data.data[key];

      if (!_status_list.hasOwnProperty(santral)) {
        _status_list[santral] = {};
      }
      if (!_status_list[santral].hasOwnProperty(res.data.data[key].INVERTER)) {
        _status_list[santral][res.data.data[key].INVERTER] = res.data.data[key];
      }
    });

    Object.keys(inverterInfo).forEach((item) => {
      Object.keys(inverterInfo[item].INVERTER).forEach((k) => {
        const inv = inverterInfo[item].INVERTER[k];

        if (!_status_list.hasOwnProperty(item)) {
          _status_list[item] = {};
        }

        if (!_status_list[item].hasOwnProperty(inv.INVERTER)) {
          _status_list[item][inv.INVERTER] = inverterInfo[item].INVERTER[inv
            .INVERTER];
          _status_list[item][inv.INVERTER].status = 0;
        } else {
          _status_list[item][inv.INVERTER].status = 1;
        }
        _status_list[item][inv.INVERTER].installedDcPower = inverterInfo[item]
          .INVERTER[inv
            .INVERTER].installedDcPower;
      });
    });

    resolve(_status_list);
  });
});

const heatmapStatus = (formdata, inverterInfo) => new Promise((resolve) => {
  request.Api('/api/inverter/status', formdata, 'post').then((res) => {
    const _status_list = {};
    Object.keys(res.data.data).forEach((key) => {
      if (!_status_list.hasOwnProperty(res.data.data[key].santral)) {
        _status_list[res.data.data[key].santral] = [];
      }
      _status_list[res.data.data[key].santral].push(res.data.data[key]);
    });
    resolve(_status_list);
  });
});

// eslint-disable-next-line camelcase
const heatmapWeeklyHourMeans_inverter_diff = (formdata, measureSelect) => new Promise((resolve) => {
  const hourData = [];
  let max = 10;

  request.Api('/api/inverter/list', formdata, 'post').then((res) => {
    if (res.data.success === 'true') {
      const tmpGroupData = {};
      const totalGroupData = {};
      Object.keys(res.data.data).forEach((key) => {
        const hour = parseInt(res.data.data[key].Hour);

        if (hour > 5 && hour < 20) {
          const minute = parseInt(res.data.data[key].MinuteGrup);

          let h;
          let m;
          hour < 10 ? h = `0${hour}` : h = `${hour}`;
          minute < 10 ? m = `0${minute}` : m = `${minute}`;

          const time = `${h}:${m}`;

          if (tmpGroupData[time] === undefined) {
            tmpGroupData[time] = [];
            totalGroupData[time] = {
              count: 0,
              index: 0,
            };
          }

          let val = res.data.data[key][measureSelect];
          val = helper.show_float_val(val);

          if (max < val) {
            max = val;
          }

          if (val > 0) {
            totalGroupData[time].count += val;
            totalGroupData[time].index += 1;
          }

          tmpGroupData[time].push(res.data.data[key]);
        }
      });

      Object.keys(tmpGroupData).forEach((k) => {
        if (totalGroupData[k].count > 0 && totalGroupData[k].index > 0) {
          totalGroupData[k].mean = totalGroupData[k].count / totalGroupData[k].index;
        }

        Object.keys(tmpGroupData[k]).forEach((i) => {
          const val = tmpGroupData[k][i][measureSelect];
          const f = totalGroupData[k].mean * (90 / 100);

          const colors = ['#23a643', '#f03a2e'];

          const obj = {};
          if (f > val) {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[1];
          } else {
            // eslint-disable-next-line prefer-destructuring
            obj.color = colors[0];
          }

          obj.INVERTERNAME = tmpGroupData[k][i].INVERTERNAME;
          obj.value = val;
          obj.time = k;
          obj.mean = totalGroupData[k].mean;

          if (f > 0) {
            obj.meanval = f;
          } else {
            obj.meanval = 0;
          }

          hourData.push(obj);
        });
      });

      resolve(hourData);
    }
  });
});

export default {
  heatmapHourly,
  heatmapStatus,
  heatmapWeeklyHourMeans_inverter_diff,

};
